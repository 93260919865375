<template>
  <v-form ref="form" lazy-validation>
    <v-card :color="ant" class="form-div" >
      <v-card-text class="">
        <v-row>
          <v-col>
            <label for=""> *Assignment Type:</label>
            <v-select
              v-model="assignment_type"
              solo
              single-line
              outlined
              placeholder="Required"
              :rules="required"
              dense
              item-text="title"
              item-value="title"
              :background-color="ant"
              class="search-input"
              required
              :items="assignItems"
            >
            </v-select>
          </v-col>
          <v-col>
            <label for=""> *Pages:</label>
            <v-text-field
              v-model="pages"
              solo
              single-line
              outlined
              :rules="digits"
              dense
              :background-color="ant"
              class="search-input"
              required
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="">
          <v-col>
            <label for=""> *Service:</label>
            <v-select
              v-model="service"
              solo
              single-line
              outlined
              placeholder="Required"
              item-text="title"
              item-value="title"
              :rules="required"
              dense
              :background-color="ant"
              class="search-input"
              required
              :items="serviceItems"
            >
            </v-select>
          </v-col>
          <v-col>
            <label for=""> *Academic Level:</label>
            <v-select
              v-model="level"
              solo
              outlined
              single-line
              item-text="title"
              item-value="title"
              :rules="required"
              dense
              :background-color="ant"
              class="search-input"
              required
              :items="levelItems"
            >
            </v-select>
          </v-col>
        </v-row>

        <v-row class="">
          <v-col>
            <label for=""> *Final Due Date:</label>
            <v-datetime-picker
             v-model="final_date"
             :text-field-props="textFieldProps"
             class="round-md"
            > 
            </v-datetime-picker>
          </v-col>
          <v-col></v-col>
        </v-row>

        <v-row class="">
          <v-col>
            <label for=""> *Language:</label>
            <v-select
              v-model="language"
              outlined
              solo
              single-line
              placeholder="Required"
              item-text="title"
              item-value="title"
              :rules="required"
              dense
              :background-color="ant"
              class="search-input"
              required
              :items="languageItems"
            >
            </v-select>
          </v-col>
          <v-col> </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-row class="mt-5 ml-2 sticky">
      Estimated Price: ${{ formatAmount(getAmount) }}
      <v-spacer> </v-spacer>
      <v-btn depressed color="primary" class="mr-5 px-10" :loading='loading' @click="checkBefore">
        Next
        <v-icon>
          mdi-arrow-right
        </v-icon>
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import colorMxn from "@/mixins/colorMxn";
import validationMxn from "@/mixins/validation_mixin";
import generalMxn from "@/mixins/general_mixin";

export default {
  name: "OrderRequirements",
  mixins: [colorMxn, validationMxn, generalMxn],
  props: ['swapComponent'],
  data() {
    return {
      loading: false,
      assignItems: [
        "Essay (Any Type)",
        "Admission Essay",
        "Annotated Bibliography",
        "Article Review"
      ],
      serviceItems: ["Writing", "Rewriting", "Editing", "Proofreading"],
      levelItems: ["School", "College", "University", "Master's", "Doctrate"],
      languageItems: ["English (US)", "English (Uk)", "French", "Spanish"],
      assignment_type: '',
      pages: '',
      service: '',
      level: '',
      final_date: '',
      language: '',
      amount: '',
      discount: 0,
      textFieldProps: {
        rules: [v => !!v || "Field is required"],
        solo: true,
        dense: true,
        outlined: true,
        singleLine: true,
        backgroundColor: '#273142',
      },
    };
  },
  computed: {
    ...mapGetters(['getCurrentComponent', 'getSession', 'getDirectory', 'getAmount', 'getOrderObject']),
  },
  watch: {
    swapComponent(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.checkBefore();
      }
    },
    getDirectory(val) {
      this.serviceItems = val ? this.getDirectory.services : this.serviceItems;
      this.levelItems = val ? this.getDirectory.levels : this.levelItems;
      this.languageItems = val ? this.getDirectory.languages : this.languages;
      this.assignItems = val? this.getDirectory.types : this.assignItems;
    },
    service(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.calcPrice();
      }
    },
    pages(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.calcPrice();
      }
    },
    getOrderObject(val) {
      this.level = val.level;
      this.assignment_type = val.assignment_type;
      this.pages = val.pages;
      this.service = val.service;
    },
    getOrderDetail(val) {
      if(val) {
        this.prefill();
      }
    }
  },
  mounted() {
    if (this.$route.name === 'customerDraft') {
      this.prefill();
      return;
    }
    this.serviceItems = this.getDirectory ? this.getDirectory.services : this.serviceItems;
    this.levelItems = this.getDirectory ? this.getDirectory.levels : this.levelItems;
    this.languageItems = this.getDirectory ? this.getDirectory.languages : this.languages;
    this.assignItems = this.getDirectory ? this.getDirectory.types : this.assignItems;
    this.level = this.getOrderObject ? this.getOrderObject.level : '';
    this.assignment_type = this.getOrderObject ? this.getOrderObject.assignment_type: '';
    this.pages = this.getOrderObject ? this.getOrderObject.pages: '';
    this.service = this.getOrderObject ? this.getOrderObject.service: '';
  },
  methods: {
    ...mapMutations(['setOrderRequirementError', 'setCurrentComponent', 'setAmount']),
    checkBefore() {
      if (this.$route.name === 'customerDraft') {
        this.orderUpdate();
      }
      else {
        this.orderSubmit();
      }
    },
    async orderSubmit() {
      this.loading = true;
      if (!this.$refs.form.validate()) {
        this.setOrderRequirementError(true);
        this.loading = false;
        return;
      }
      const datetime = this.final_date.toISOString().slice(0, 19).replace('T', ' ')
      const payload = {
        assignment_type: this.assignment_type,
        pages: this.pages,
        services: this.service,
        level: this.level,
        final_date: datetime,
        language: this.language,
        customer_id: this.getSession.customer.id,
        amount: this.getAmount,
      };
       this.setOrderRequirementError(false);
      const fullPayload = {
        params: payload,
        endpoint: '/orders'
      }
      const response = await this.performPostActions(fullPayload);
      if (response.status === 200 ) {
        this.$router.push(`/order/draft/${response.data.id}`)
        this.setCurrentComponent('OrderDetails');
      }
      this.loading = false;
    },
    async orderUpdate() {
      this.loading = true;
      if (!this.$refs.form.validate()) {
        this.setOrderRequirementError(true);
        this.loading = false;
        return;
      }
      this.setOrderRequirementError(false);
      const datetime = this.final_date.toISOString().slice(0, 19).replace('T', ' ')
      const payload = {
        assignment_type: this.assignment_type,
        pages: this.pages,
        services: this.service,
        level: this.level,
        final_date: datetime,
        language: this.language,
        amount: this.getAmount,
      };

      const fullPayload = {
        params: payload,
        endpoint: `/orders/${this.$route.params.id}`,
      }
      const response = await this.performUpdateActions(fullPayload);
      if (response.status === 200 ) {
        this.setCurrentComponent('OrderDetails');
      }
      this.loading = false;
    },
    prefill() {
      this.assignment_type = this.getOrderDetail.assignment_type;
      this.pages = this.getOrderDetail.pages;
      this.service = this.getOrderDetail.services;
      this.level = this.getOrderDetail.level;
      const formatedDate = new Date(this.getOrderDetail.final_date);
      this.final_date = formatedDate
      this.language = this.getOrderDetail.language;
      
    },
    checkValid() {
      if (this.assignment_type && this.pages && this.service && this.level && this.final_date) {
        return true;
      }
      else{
        return false;
      }
    },
    calcPrice() {
      const writing = 10.4;
      const editing = 10.4;
      const rewriting = 6.98;
      const proofreading = 4.98;

      let amount = 10.4;
      switch (this.service) {
        case 'Writing': 
          amount = parseInt(this.pages) * writing; 
          break;
        case 'Rewriting': 
          amount = parseInt(this.pages) * rewriting; 
          break;
        case 'Editing': 
          amount = parseInt(this.pages) * editing; 
          break;
        case 'Proofreading': 
          amount = parseInt(this.pages) * proofreading; 
          break;
        default:
          break;
      }
      this.setAmount(amount);
    }
  }
};
</script>
